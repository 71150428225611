<body>


    <header class="headerGrid">

        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <img (click)="seehome()" id="desktoplogo" src="../../assets/media/Logo DMX_w.png"
                    alt="DMX Construction logo">

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                            <img (click)="seehome()" id="mobilelogo" src="../../assets/media/Logo DMX_w.png"
                                alt="DMX Construction logo">
                        </div>


                        <div class="header-phone"><span id="header-icon-phone" class="material-symbols-outlined"><a
                                    href="tel:+40721229999"> </a> </span></div>
                        <div class="header-mail"><span id="header-icon-mail" class="material-symbols-outlined"> <a
                                    href="mailto:sales@thelake.ro"> </a></span></div>
                        <!-- Button trigger modal vizionare-->
                    </div>

                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                               
                                <a class="blue-border" (click)="seehome()">Acasa</a>
                                <a class="blue-border" (click)="seecofraje()" >Vezi cofraje</a>
                                <a class="blue-border" (click)="scrollToContact()">Contact</a>
                                <a (click)="contulmeu()">Profil</a>
                                <a class="blue-border" (click)="handleLoginLogout()">{{ loginText }}</a>                                
                            </div>

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a (click)="seehome()">Acasa</a>
                            <a (click)="seecofraje()" >Vezi cofraje</a>
                            <a (click)="scrollToContact()">Contact</a>
                            <a (click)="contulmeu()">Profil</a>     
                            <a (click)="handleLoginLogout()">{{ loginText }}</a>           
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <!--<section class="imgheader-wrapper">
            <div class="imgheader">
                <h1 class="title">Anunt</h1>
            </div>
        </section>-->
    </header>


    <section class="page-content-wrapper"  *ngIf="seecofrajelemele">
        <div class="page-content">
            <div class="page-titles">
                <h1 class="title">Anunțurile tale</h1>

                <div class="btns">
                    <button class="add" (click)="showAddForm()">Adaugă anunț</button>
                    <button class="others" (click)="seecofraje()">Vezi alte anunțuri</button>
                  </div>
            </div>
            <p class="about">Aici îți poți gestiona rapid și ușor toate anunțurile de pe platforma noastră. De pe această pagină, ai acces la toate opțiunile necesare pentru a-ți administra activitatea: </p>
                <span>
               <strong> &#8226; Gestionează Anunțurile Tale:</strong> Poți vedea toate anunțurile pe care le-ai postat, urmărind starea fiecăruia și actualizând informațiile după cum este necesar. <br>
               <br> <strong> &#8226; Adaugă un Anunț Nou:</strong> Ai un cofraj recondiționat gata de vânzare? Aici poți adăuga rapid un anunț nou, completând detaliile și încărcând fotografii relevante.<br>
               <br> <strong> &#8226; Șterge Anunțurile Expirate:</strong> Când un anunț nu mai este valabil, îl poți șterge cu ușurință pentru a menține contul tău ordonat și relevant.
            </span>
              <p class="about down"> Platforma noastră îți oferă toate instrumentele necesare pentru a-ți gestiona eficient prezența online și a-ți maximiza șansele de succes.
            
            Ia controlul asupra anunțurilor tale acum și optimizează-ți activitatea pe platformă!</p>

            <div class="contents-table">
                <div *ngFor="let cofraj of userCofraje" class="offers">
                    <img [src]="'https://api.cofraje.dmxconstruction.ro/media/' + cofraj.Imagini.split(',')[0]" alt="Image of {{ cofraj.Denumire }}">                    
                    <div class="offers-txt">
                        <h3>{{cofraj.Denumire}}</h3>
                        <p class="description">{{ cofraj.Descriere }}</p>
                        <p class="tip">{{ cofraj.Tip }}</p>
                    </div>
                    <div class="profil-btns">
                        <div class="edit" (click)="editCofraj(cofraj)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                          </svg></div>
                          <div class="remove" (click)="confirmDelete(cofraj)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                            </svg>
                          </div>
                          
                    </div>
                </div>
            </div>
        </div>
    </section>

    <app-addformwork *ngIf="showAddFormwork"></app-addformwork>
    <app-editformwork *ngIf="showEditFormwork"></app-editformwork>
    
    <footer>

        <div class="gov">
            <img src="../../assets/media/1724831532016blob.jpg" alt="DMX Construction Logo" class="ajutor-financiar-img">

            <p class="ajutor-financiar">©  cofraje.dmxconstruction.ro – Toate drepturile sunt rezervate DMX Construction – 
                Obtinut cu sprijin financiar de la bugetul de stat prin Ministerul Economiei</p>
        </div>
        
        <div class="footer-wrapper">
            <div class="footer-logo">
                <!--<p>Proiect dezvoltat de:</p>-->
                <img (click)="seehome()" src="../../assets/media/Logo DMX_w.png" alt="DMX Construction Logo">
            </div>
            <div class="footer1">
                <!--<p class="footer1-bold">The Lake Home</p>
            <h5>Contact</h5>-->
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”>
                    <p>Strada Tractorului, nr. 12-28,<br>Sibiu, România</p>
                </a>
                <div>
                    <span>T.</span><a href="tel:+40371306055">+40 371 306 055</a> <br><br>
                    <span>E.</span><a href="mailto:office@dmxconstruction.ro">office@dmxconstruction.ro</a>
                </div>
            </div>
            <div class="footer2">
                <div class="footer2-socialMedia">
                    <img src="../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" (click)="facebook()">
                    <img src="../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" (click)="linkedin()">
                    <img src="../../assets/media/icons/website_logo_icon.svg" alt="Instagram link" (click)="instagram()">
                </div>
                <div class="footer2-end">
                    <a>Politica de confidențialitate | Cookies</a>
                    <p>Brand & Web Design : <a href="" target="_blank">Hello Sib SRL</a></p>
                </div>
            </div>
        </div>
    </footer>

</body>


                   <!--<div class="profil-btns">
                        <button class="edit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                          </svg> Editează</button>
                        <button class="remove"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                          </svg> Șterge</button>  
                    </div>-->