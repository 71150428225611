import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cofraje',
  templateUrl: './cofraje.component.html',
  styleUrls: ['./cofraje.component.css']
})
export class CofrajeComponent implements OnInit {

  formworks: any[] = [];  // Array to hold all formworks
  filteredFormworks: any[] = [];  // Array to hold filtered formworks
  counties: string[] = [];  // Array to hold the list of counties
  selectedCounty: string = '';  // Selected county
  selectedType: string = '';  // Selected formwork type
  isLoggedIn: boolean = false;
  loginText: string = 'Autentificare';

  constructor(private service: SharedService, private router: Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.initializeCounties();
    this.fetchFormworks();
    this.checkLoginStatus();
  }

  checkLoginStatus(): void {
    this.isLoggedIn = this.service.isLoggedIn();
    this.loginText = this.isLoggedIn ? 'Iesire din cont' : 'Autentificare';
  }

  handleLoginLogout(): void {
    if (this.isLoggedIn) {
      this.service.logoutUser();
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  fetchFormworks(): void {
    this.service.getFormworks().subscribe(data => {
      // Sort formworks in descending order based on FormworkId
      this.formworks = data.sort((a, b) => b.FormworkId - a.FormworkId);
      this.filteredFormworks = this.formworks; // Initially show all formworks
    });
  }
  

  initializeCounties(): void {
    this.counties = [
      'Alba', 'Arad', 'Argeş', 'Bacău', 'Bihor', 'Bistriţa-Năsăud', 'Botoşani', 'Braşov', 'Brăila', 'Buzău',
      'Caraş-Severin', 'Călăraşi', 'Cluj', 'Constanţa', 'Covasna', 'Dâmboviţa', 'Dolj', 'Galaţi', 'Giurgiu', 'Gorj',
      'Harghita', 'Hunedoara', 'Ialomiţa', 'Iaşi', 'Ilfov', 'Maramureş', 'Mehedinţi', 'Mureş', 'Neamţ', 'Olt',
      'Prahova', 'Satu Mare', 'Sălaj', 'Sibiu', 'Suceava', 'Teleorman', 'Timiş', 'Tulcea', 'Vaslui', 'Vâlcea', 'Vrancea'
    ];
  }

  onCountyChange(): void {
    this.applyFilters();
  }

  onTypeChange(): void {
    this.applyFilters();
  }

  applyFilters(): void {
    this.filteredFormworks = this.formworks.filter(cofraj => {
      return (this.selectedCounty === '' || cofraj.Localitate.includes(this.selectedCounty)) &&
             (this.selectedType === '' || cofraj.Tip === this.selectedType);
    });
  }

  navSlider(): void {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.mob-navbar');
    const navBar = document.querySelectorAll<HTMLElement>('.mob-navbar a');
    // Toggle nav rectangle
    nav!.classList.toggle('nav-active');
    // Animate links
    navBar.forEach((link, index) => {
      if (link.style.animation) {
        link.style.animation = '';
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.01}s`;
      }
    });
    // Burger animation
    burger!.classList.toggle('toggle');
  }

  seecofraje(): void {
    this.router.navigateByUrl('/cofraje');
  }
  
  seehome(): void {
    this.router.navigateByUrl('/home');
  }

  contulmeu(): void {
    const currentUser = localStorage.getItem('currentUser');
  
    if (currentUser) {
      this.router.navigateByUrl('/contulmeu');
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  scrollToContact(): void {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // New method to navigate to the formwork details
  viewCofraj(cofraj: any): void {
    this.service.setSelectedFormwork(cofraj);  // Save the selected cofraj in the service
    const cofrajUrl = cofraj.Denumire.toLowerCase().replace(/[^a-z0-9]+/g, '-');
    this.router.navigate(['/vezi-cofrag', cofrajUrl]);
  }

  facebook(): void {
    window.open('https://www.facebook.com/dmxconstructionsibiu', '_blank');
  }

  // Function to navigate to the LinkedIn page
  linkedin(): void {
    window.open('https://www.linkedin.com/company/dmx-construction/?viewAsMember=true', '_blank');
  }

  // Function to navigate to the official website
  instagram(): void {
    window.open('https://www.dmxconstruction.ro/', '_blank');
  }
}
