import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isLoggedIn: boolean = false;
  loginText: string = 'Autentificare';

  constructor(private service: SharedService, private router: Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.checkLoginStatus();
  }

  checkLoginStatus(): void {
    this.isLoggedIn = this.service.isLoggedIn();
    this.loginText = this.isLoggedIn ? 'Iesire din cont' : 'Autentificare';
  }

  handleLoginLogout(): void {
    if (this.isLoggedIn) {
      this.service.logoutUser();
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/login']);
    }
  }
  

  navSlider(): void {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.mob-navbar');
    const navBar = document.querySelectorAll<HTMLElement>('.mob-navbar a');
    // Toggle nav rectangle
    nav!.classList.toggle('nav-active');
    // Animate links
    navBar.forEach((link, index) => {
      if (link.style.animation) {
        link.style.animation = '';
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.01}s`;
      }
    });
    // Burger animation
    burger!.classList.toggle('toggle');
  }

  seecofraje(): void {
    this.router.navigateByUrl('/cofraje');
  }
  
  seehome(): void {
    this.router.navigateByUrl('/home');
  }
  contulmeu(): void {
    // Check if the user is logged in by checking if 'currentUser' exists in localStorage
    const currentUser = localStorage.getItem('currentUser');
  
    if (currentUser) {
      // If user is logged in, navigate to the profile page
      this.router.navigateByUrl('/contulmeu');
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigateByUrl('/login');
    }
  }

  scrollToContact(): void {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  }

  facebook(): void {
    window.open('https://www.facebook.com/dmxconstructionsibiu', '_blank');
  }

  // Function to navigate to the LinkedIn page
  linkedin(): void {
    window.open('https://www.linkedin.com/company/dmx-construction/?viewAsMember=true', '_blank');
  }

  // Function to navigate to the official website
  instagram(): void {
    window.open('https://www.dmxconstruction.ro/', '_blank');
  }
}
