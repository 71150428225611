import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-seecofraje',
  templateUrl: './seecofraje.component.html',
  styleUrls: ['./seecofraje.component.css']
})
export class SeecofrajeComponent implements OnInit {

  cofraj: any;
  slideIndex: number = 0;
  isLoggedIn: boolean = false;
  loginText: string = 'Autentificare';
  phoneNumberVisible: boolean = false;
  interestExpressed: boolean = false;  // To track if the user has expressed interest

  constructor(private service: SharedService, private router: Router) { }

  ngOnInit(): void {
    this.cofraj = this.service.getSelectedFormwork();
    if (!this.cofraj) {
      alert('No formwork selected!');
      this.router.navigate(['/cofraje']);
      return;
    }
    this.checkLoginStatus();
  }

  checkLoginStatus(): void {
    this.isLoggedIn = this.service.isLoggedIn();
    this.loginText = this.isLoggedIn ? 'Iesire din cont' : 'Autentificare';
  }

  handleLoginLogout(): void {
    if (this.isLoggedIn) {
      this.service.logoutUser();
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  expressInterest(): void {
    if (this.isLoggedIn) {
      this.service.getCurrentUser().subscribe(currentUser => {
        const email = currentUser.Mail;  // Use email instead of UserId
  
        this.service.expressInterest(this.cofraj.FormworkId, email).subscribe(
          response => {
            this.interestExpressed = true;
            this.showPhoneNumber();
            alert(response.message);
          },
          error => {
            console.error('Error expressing interest:', error);
            alert('A apărut o problemă. Încearcă din nou.');
          }
        );
      });
    } else {
      alert('Trebuie să fii autentificat pentru a exprima interesul.');
      this.router.navigate(['/login']);
    }
  }
  
  

  showPhoneNumber(): void {
    this.phoneNumberVisible = true;
  }

  plusSlides(n: number): void {
    const images = this.cofraj.Imagini.split(',');
    this.slideIndex += n;

    if (this.slideIndex >= images.length) {
      this.slideIndex = 0;
    } else if (this.slideIndex < 0) {
      this.slideIndex = images.length - 1;
    }
  }

  navSlider(): void {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.mob-navbar');
    const navBar = document.querySelectorAll<HTMLElement>('.mob-navbar a');
    nav!.classList.toggle('nav-active');
    navBar.forEach((link, index) => {
      if (link.style.animation) {
        link.style.animation = '';
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.01}s`;
      }
    });
    burger!.classList.toggle('toggle');
  }

  seecofraje(): void {
    this.router.navigateByUrl('/cofraje');
  }

  seehome(): void {
    this.router.navigateByUrl('/home');
  }

  contulmeu(): void {
    const currentUser = localStorage.getItem('currentUser');

    if (currentUser) {
      this.router.navigateByUrl('/contulmeu');
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  scrollToContact(): void {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  }

  facebook(): void {
    window.open('https://www.facebook.com/dmxconstructionsibiu', '_blank');
  }

  linkedin(): void {
    window.open('https://www.linkedin.com/company/dmx-construction/?viewAsMember=true', '_blank');
  }

  instagram(): void {
    window.open('https://www.dmxconstruction.ro/', '_blank');
  }
}
