<head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
</head>
<body>
    

    <header class="headerGrid">
        <div class="header-bcground"></div>

        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <img (click)="seehome()" id="desktoplogo" src="../../assets/media/Logo DMX_w.png" alt="DMX Construction logo" >

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                            <img (click)="seehome()" id="mobilelogo" src="../../assets/media/Logo DMX_w.png" alt="DMX Construction logo" >
                        </div>

                        
                        <div class="header-phone"><span id="header-icon-phone" class="material-symbols-outlined"><a href="tel:+40721229999"> </a> </span></div>
                        <div class="header-mail"><span id="header-icon-mail" class="material-symbols-outlined">  <a href="mailto:sales@thelake.ro"> </a></span></div>
                        <!-- Button trigger modal vizionare-->
                    </div>

                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                               
                                <a class="blue-border" (click)="seehome()">Acasa</a>
                                <a class="blue-border" (click)="seecofraje()" >Vezi cofraje</a>
                                <a class="blue-border" (click)="scrollToContact()">Contact</a>
                                <a (click)="contulmeu()">Profil</a>
                                <a class="blue-border" (click)="handleLoginLogout()">{{ loginText }}</a>                                
                            </div>

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a (click)="seehome()">Acasa</a>
                            <a (click)="seecofraje()" >Vezi cofraje</a>
                            <a (click)="scrollToContact()">Contact</a>
                            <a (click)="contulmeu()">Profil</a> 
                            <a (click)="handleLoginLogout()">{{ loginText }}</a>              
                        </div>
                    </div>
                </div>
            </div>  

        </div>

        <section class="imgheader-wrapper">
            <div class="imgheader">
                <h1 class="title">Platforma de <br>reciclare a Cofrajelor</h1>
                <h4>Bine ai venit pe platforma noastră unică la nivel național, dedicată reciclării și recondiționării cofrajelor uzate din lemn stratificat, scândură și metal. Suntem aici pentru a facilita interacțiunea între cei care dețin cofraje uzate și prestatorii de servicii specializați în recondiționarea acestora. Platforma noastră oferă un spațiu centralizat pentru cererea și oferta de cofraje, contribuind astfel la un mediu mai curat și la reutilizarea resurselor existente.</h4>
                <button class="more" (click)="seecofraje()" >Descopera mai mult</button>
            </div>
        </section>
    </header>
   

    <section class="page-content-wrapper">

        <div class="page-content" id="slide">
            <h2 id="content-1">Cofraj de scândura</h2>
    
            <img id="content-2" class="lifestyle-icon" src="../../assets/media/cofraj-1.webp" alt="">
    
            <p id="content-3">Cofrajele din scândură sunt esențiale în multe proiecte de construcții datorită rezistenței și versatilității lor. Acestea pot fi reutilizate de mai multe ori, însă, odată uzate, devin un candidat perfect pentru reciclare. Prin recondiționarea cofrajelor din scândură, le prelungim durata de viață și contribuim la reducerea deșeurilor din lemn, oferindu-le o nouă utilitate în proiectele viitoare.</p>
        </div>

        <div class="division"> </div>

        <div class="page-content oposite">
            <h2 id="content-1 ">Lemn Stratificat</h2>
    
            <img id="content-2" class="lifestyle-icon" src="../../assets/media/cofraj-2.png" alt="">
    
            <p id="content-3">Cofrajele din lemn stratificat sunt recunoscute pentru durabilitatea și eficiența lor în construcții. Acestea sunt create din straturi multiple de lemn presat, care asigură o rezistență superioară. Când aceste cofraje își pierd funcționalitatea inițială, reciclarea lor devine o opțiune viabilă și sustenabilă. Recondiționarea cofrajelor din lemn stratificat asigură păstrarea calităților lor de bază, reducând în același timp consumul de materiale noi.</p>
        </div>

        <div class="division"> </div>

        <div class="page-content" id="slide">
            <h2 id="content-1">Metal</h2>
    
            <img id="content-2" class="lifestyle-icon" src="../../assets/media/cofraj-1.webp" alt="">
    
            <p id="content-3">Cofrajele metalice reprezintă o soluție robustă și durabilă pentru construcțiile moderne. Acestea sunt extrem de rezistente și pot fi utilizate în multiple cicluri de construcție. Cu toate acestea, odată ce devin nefuncționale, reciclarea acestora este esențială pentru a evita acumularea deșeurilor metalice și pentru a recupera materialele valoroase. Procesul de recondiționare al cofrajelor metalice asigură revenirea acestora în circuitul economic, pregătite să fie folosite din nou.</p>
        </div>
    </section>


    <!--<div class="projects">
        <div class="threeProjects" >			
            <div class="projectsBtns" id="thelake"> 
                <div class="aGreyBlock1"></div>
            </div>
            
            <div class="projectsBtns" id="theriver" > 
                <div class="aGreyBlock2"></div>
            </div>
            <div class="projectsBtns" id="riverHotel" > 
                <div class="aGreyBlock3"></div>
            </div>
        </div>   
        <div class="btn-wrapper">
            <button class="button-team" >Echipa Novarion</button>
        </div>
    </div>-->     

  
    <footer id="contact">
        <div class="gov">
            <img src="../../assets/media/1724831532016blob.jpg" alt="DMX Construction Logo" class="ajutor-financiar-img">

            <p class="ajutor-financiar">©  cofraje.dmxconstruction.ro – Toate drepturile sunt rezervate DMX Construction – 
                Obtinut cu sprijin financiar de la bugetul de stat prin Ministerul Economiei</p>
        </div>
        
        <div class="footer-wrapper">
           
            <div class="footer-logo">
                <!--<p>Proiect dezvoltat de:</p>-->

                <img src="../../assets/media/Logo DMX_w.png" alt="DMX Construction Logo" (click)="seehome()">
            </div>
            <div class="footer1">
                <!--<p class="footer1-bold">The Lake Home</p>
                <h5>Contact</h5>-->
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”><p>Strada Tractorului, nr. 12-28,<br>Sibiu, România</p></a> 
                <div>
                    <span>T.</span><a href="tel:+40371306055">+40 371 306 055</a> <br><br>
                    <span>E.</span><a href="mailto:office@dmxconstruction.ro">office@dmxconstruction.ro</a>
                </div>
            </div>
            <div class="footer2">
                <div class="footer2-socialMedia">
                    <img src="../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" (click)="facebook()">
                    <img src="../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" (click)="linkedin()">
                    <img src="../../assets/media/icons/website_logo_icon.svg" alt="Instagram link" (click)="instagram()">
                </div>
                <div class="footer2-end">
                    <a >Politica de confidențialitate | Cookies</a>
                    <p>Brand & Web Design : <a href="" target="_blank">Hello Sib SRL</a></p>
                </div>
            </div>
        </div>  
    </footer>
</body>
