<body>
  <div class="wrapper fadeInDown">
    <div id="formContent">
      <!-- Tabs Titles -->
      <h2 [class.active]="showConectareDiv" [class.inactive]="!showConectareDiv" (click)="showConectare()">Autentificare</h2>
      <h2 [class.active]="!showConectareDiv" [class.inactive]="showConectareDiv" (click)="showInscriere()">Inscrie-te</h2>

      <!-- Login Form -->
      <div class="conectare" *ngIf="showConectareDiv">
        <!-- Icon -->
        <div class="fadeIn first">
          <img src="../../assets/media/LogoDMX_s.png" id="icon" alt="User Icon" />
        </div>

        <form>
          <input [(ngModel)]="username" type="text" id="login" class="fadeIn second" name="login" placeholder="email">
          <input [(ngModel)]="password" type="password" id="password" class="fadeIn third" name="login" placeholder="parola">
          <input (click)="loginUser()" type="submit" class="fadeIn fourth" value="Log In">
        </form>

        <!-- Remind Password -->
        <div id="formFooter">
          <a class="underlineHover" href="#" (click)="openForgotPasswordModal()">Ai uitat parola?</a>
        </div>
      </div>

      <div class="inscriere" *ngIf="!showConectareDiv">
        <!-- Icon -->
        <div class="fadeIn first">
          <img src="../../assets/media/LogoDMX_s.png" id="icon" alt="User Icon" />
        </div>

        <!-- Sign In Form -->
        <form (ngSubmit)="registerUser()">
          <input [(ngModel)]="user.Nume" type="text" id="nume" class="fadeIn second" name="nume" placeholder="nume" required>
          <input [(ngModel)]="user.Prenume" type="text" id="prenume" class="fadeIn second" name="prenume" placeholder="prenume" required>
          <input [(ngModel)]="user.Mail" type="text" id="email" class="fadeIn second" name="email" placeholder="email" required>
          <input [(ngModel)]="user.Parola" type="password" id="password" class="fadeIn third" name="password" placeholder="parola" required>
          <input [(ngModel)]="user.Telefon" type="text" id="telefon" class="fadeIn second" name="telefon" placeholder="telefon" required>
          
          <!-- Add Role Selection -->
          <div class="role-selection fadeIn fourth">
            <label>
              <input type="radio" [(ngModel)]="user.Rol" name="rol" value="Vând cofraje uzate" required>
              Vând cofraje uzate
            </label>
            <label>
              <input type="radio" [(ngModel)]="user.Rol" name="rol" value="Cumpăr cofraje uzate" required>
              Cumpăr cofraje uzate
            </label>
          </div>

          <div class="privacylink2">
            <div class="checkdiv">
              <input id="checkbox2" type="checkbox" name="acceptTerms" [(ngModel)]="user.AcceptTerms" required>
            </div>
            <div>
              <label class="form-control2">
                Sunt de acord cu 
                <a id="ppLink2" (click)="seetermeni()">Termenii și Condiții</a>
              </label>
            </div>
          </div>
          
          <p *ngIf="showErrorMessage" class="error-message">Toate câmpurile sunt obligatorii!</p>
          <p *ngIf="showTermsErrorMessage" class="error-message">Trebuie să acceptați termenii și condițiile!</p>
          <input type="submit" class="fadeIn fourth" value="Inregistrare">
        </form>

        <!-- Remind Password -->
      </div>
    </div>
  </div>

  <!-- Forgot Password Modal -->
  <div id="forgotPasswordModal" class="modal" [class.show]="showForgotPasswordModal">
    <div class="modal-content">
      <span class="close" (click)="closeForgotPasswordModal()">&times;</span>
      <h2>Recuperare Parola</h2>
      <p>Introduceți adresa de email pentru a vă trimite parola:</p>
      <input [(ngModel)]="forgotPasswordEmail" type="email" placeholder="Adresa de email">
      <button (click)="sendForgotPasswordEmail()">Trimite</button>
    </div>
  </div>
</body>
