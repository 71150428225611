import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedService } from './shared.service';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { HomeComponent } from './home/home.component';
import { CofrajeComponent } from './cofraje/cofraje.component';
import { AccountComponent } from './account/account.component';
import { AddformworkComponent } from './account/addformwork/addformwork.component';
import { SeecofrajeComponent } from './cofraje/seecofraje/seecofraje.component';
import { EditformworkComponent } from './account/editformwork/editformwork.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    CofrajeComponent,
    AccountComponent,
    AddformworkComponent,
    SeecofrajeComponent,
    EditformworkComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
  ],
  providers: [SharedService],
  bootstrap: [AppComponent]
})
export class AppModule { }
