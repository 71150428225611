import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addformwork',
  templateUrl: './addformwork.component.html',
  styleUrls: ['./addformwork.component.css']
})
export class AddformworkComponent implements OnInit {
  counties: string[] = [];
  Denumire: string = '';
  Descriere: string = '';
  TipCofraj: string = '';
  Localitate: string = '';
  Nume: string = '';
  Telefon: string = '';
  Imagini: string = '';
  Propietar: string = '';
  Status: string = '';

  constructor(private service: SharedService, private router: Router) { }

  ngOnInit(): void {
    this.initializeCounties();
    this.preFillContactInfo();
  }

  initializeCounties(): void {
    this.counties = [
      'Alba', 'Arad', 'Argeş', 'Bacău', 'Bihor', 'Bistriţa-Năsăud', 'Botoşani', 'Braşov', 'Brăila', 'Buzău',
      'Caraş-Severin', 'Călăraşi', 'Cluj', 'Constanţa', 'Covasna', 'Dâmboviţa', 'Dolj', 'Galaţi', 'Giurgiu', 'Gorj',
      'Harghita', 'Hunedoara', 'Ialomiţa', 'Iaşi', 'Ilfov', 'Maramureş', 'Mehedinţi', 'Mureş', 'Neamţ', 'Olt',
      'Prahova', 'Satu Mare', 'Sălaj', 'Sibiu', 'Suceava', 'Teleorman', 'Timiş', 'Tulcea', 'Vaslui', 'Vâlcea', 'Vrancea'
    ];
  }

  preFillContactInfo(): void {
    const currentUser = this.service.getCurrentUserValue();
    if (currentUser) {
      this.Nume = `${currentUser.Nume} ${currentUser.Prenume}`;
      this.Telefon = currentUser.Telefon;
    }
  }

  addCofraj(): void {
    const val = {
      Denumire: this.Denumire.trim(),
      Descriere: this.Descriere.trim(),
      Tip: this.TipCofraj,
      Localitate: this.Localitate,
      Propietar: this.Nume,
      Status: this.Telefon,
      Imagini: this.Imagini ? this.Imagini : '' // Ensure Imagini is a string (empty if not set)
    };
  
    // Check if any required fields are empty
    if (!val.Denumire || !val.Descriere || !val.Tip || !val.Localitate || !val.Propietar || !val.Status) {
      console.error("One or more required fields are empty.");
      return;
    }
  
    this.service.addCofraj(val).subscribe(
      res => {
        alert('Anunț adăugat cu succes!');
        console.log(res.toString());
        
      },
      err => {
        alert('A apărut o eroare la adăugarea anunțului.');
        console.log('HTTP Error', err);
      }
    );
  }

  addCofraj2(){
    var val = {
      Denumire: this.Denumire.trim(),
      Descriere: this.Descriere.trim(),
      Tip: this.TipCofraj,
      Localitate: this.Localitate,
      Propietar: this.Nume,
      Status: this.Telefon,
      Imagini: this.Imagini ? this.Imagini : '' // Ensure Imagini is a string (empty if not set)
    };

    this.service.addCofraj2(val).subscribe(
        res => console.log(res.toString(), this.router.navigateByUrl('/cofraje')),
        err => console.log('HTTP Error', err),
        () => console.log('HTTP request completed.')
    );
}
  
  
  
  

uploadPhotos(event: any): void {
  const files = event.target.files;
  const formData: FormData = new FormData();
  
  // Limit to 4 images
  if (files.length > 4) {
      alert('Poți adăuga doar până la 4 imagini.');
      return;
  }

  for (let i = 0; i < files.length; i++) {
      formData.append('uploadedFiles', files[i], files[i].name);
  }

  this.service.UploadPhoto(formData).subscribe((data: any) => {
      this.Imagini = data.file_names.join(','); // Store as a comma-separated string
      console.log(this.Imagini);
  }, error => {
      console.log('Error uploading files', error);
  });
}
  

  
}
