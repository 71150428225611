<body>


    <header class="headerGrid">
        <!--<div class="header-bcground"></div>-->

        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <img (click)="seehome()" id="desktoplogo" src="../../assets/media/Logo DMX_w.png"
                    alt="DMX Construction logo">

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                            <img (click)="seehome()" id="mobilelogo" src="../../assets/media/Logo DMX_w.png"
                                alt="DMX Construction logo">
                        </div>


                        <div class="header-phone"><span id="header-icon-phone" class="material-symbols-outlined"><a
                                    href="tel:+40721229999"> </a> </span></div>
                        <div class="header-mail"><span id="header-icon-mail" class="material-symbols-outlined"> <a
                                    href="mailto:sales@thelake.ro"> </a></span></div>
                        <!-- Button trigger modal vizionare-->
                    </div>

                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                               
                                <a class="blue-border" (click)="seehome()">Acasa</a>
                                <a class="blue-border" (click)="seecofraje()" >Vezi cofraje</a>
                                <a class="blue-border" (click)="scrollToContact()">Contact</a>
                                <a (click)="contulmeu()">Profil</a> 
                                <a class="blue-border" (click)="handleLoginLogout()">{{ loginText }}</a>                                  
                            </div>

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a (click)="seehome()">Acasa</a>
                            <a (click)="seecofraje()" >Vezi cofraje</a>
                            <a (click)="scrollToContact()">Contact</a>
                            <a (click)="contulmeu()">Profil</a>  
                            <a (click)="handleLoginLogout()">{{ loginText }}</a>              
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </header>


    <section class="page-content-wrapper">
        <div class="page-content">
            <h1 class="title">Cofraje Disponibile</h1>
            <div class="filtre">   
                <h3>Filtre:</h3>

                <div class="db-camere">
                    <div><span id="db-camere-arrow" class="material-symbols-outlined">chevron_right</span>
                    </div>
                    <div class="camere-names" id="moreCamere">                        
                        <select [(ngModel)]="selectedType" (change)="onTypeChange()" class="pret-range">
                            <option value="">Tip Cofraj</option>
                            <option value="Scandura">Scandura</option>
                            <option value="Lemn Stratificat">Lemn Stratificat</option>
                            <option value="Metalic">Metalic</option>
                        </select>
                    </div>
                    
                    <div class="camere-names" id="moreCamere">                     
                        <select [(ngModel)]="selectedCounty" (change)="onCountyChange()" class="pret-range">
                            <option value="">Localitate</option>
                            <option *ngFor="let county of counties" [value]="county">{{ county }}</option>
                        </select>
                    </div>  
                </div> 

            </div>

            <div *ngFor="let cofraj of filteredFormworks" class="offers" (click)="viewCofraj(cofraj)">
                <img [src]="'https://api.cofraje.dmxconstruction.ro/media/' + cofraj.Imagini.split(',')[0]" alt="Image of {{ cofraj.Denumire }}">
                <div class="offers-txt">
                    <h3>{{ cofraj.Denumire }}</h3>
                    <p>{{ cofraj.Descriere }}</p>
                    <p class="tip">{{ cofraj.Tip }}</p>
                    <p class="localitate">{{ cofraj.Localitate }}</p>
                </div>
            </div>
            
        </div>
    </section>

    <footer id="contact">

        <div class="gov">
            <img src="../../assets/media/1724831532016blob.jpg" alt="DMX Construction Logo" class="ajutor-financiar-img">

            <p class="ajutor-financiar">©  cofraje.dmxconstruction.ro – Toate drepturile sunt rezervate DMX Construction – 
                Obtinut cu sprijin financiar de la bugetul de stat prin Ministerul Economiei</p>
        </div>

        
        <div class="footer-wrapper">
            <div class="footer-logo">
                <!--<p>Proiect dezvoltat de:</p>-->
                <img (click)="seehome()" src="../../assets/media/Logo DMX_w.png" alt="DMX Construction Logo">
            </div>
            <div class="footer1">
                <!--<p class="footer1-bold">The Lake Home</p>
            <h5>Contact</h5>-->
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”>
                    <p>Strada Tractorului, nr. 12-28,<br>Sibiu, România</p>
                </a>
                <div>
                    <span>T.</span><a href="tel:+40371306055">+40 371 306 055</a> <br><br>
                    <span>E.</span><a href="mailto:office@dmxconstruction.ro">office@dmxconstruction.ro</a>
                </div>
            </div>
            <div class="footer2">
                <div class="footer2-socialMedia">
                    <img src="../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" (click)="facebook()">
                    <img src="../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" (click)="linkedin()">
                    <img src="../../assets/media/icons/website_logo_icon.svg" alt="Instagram link" (click)="instagram()">
                </div>
                <div class="footer2-end">
                    <a>Politica de confidențialitate | Cookies</a>
                    <p>Brand & Web Design : <a href="" target="_blank">Hello Sib SRL</a></p>
                </div>
            </div>
        </div>
    </footer>

</body>