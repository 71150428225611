import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class SharedService {
  // Declare and initialize BehaviorSubject for unseen tasks
  private unseenTasks = new BehaviorSubject<any[]>([]);
  unseenTasks$ = this.unseenTasks.asObservable();

  // Method to set unseen tasks
  setUnseenTasks(tasks: any[]) {
    this.unseenTasks.next(tasks);
  }




  private userData = new BehaviorSubject<any>(null);
  userData$ = this.userData.asObservable();

  private currentUserSubject: BehaviorSubject<any>;
  currentUser$: Observable<any>;


  readonly APIUrl = "https://api.cofraje.dmxconstruction.ro/api";
  readonly PhotoUrl = "https://api.cofraje.dmxconstruction.ro/api/media/";
  readonly LocalAPIUrl = "http://127.0.0.1:8000/api";


 
  constructor(private http: HttpClient) { 
    // Initialize currentUserSubject with user data from localStorage or null

    const storedUser = JSON.parse(localStorage.getItem('currentUser') || 'null');
    this.currentUserSubject = new BehaviorSubject<any>(storedUser);
    this.currentUser$ = this.currentUserSubject.asObservable();
  }
  getCurrentUserValue(): any {
    return this.currentUserSubject.value;
  }



  // SharedService.ts

addTask(val: any) {
  return this.http.post(this.APIUrl + '/tasks/', val);
}
getTask(): Observable<any[]> {
  return this.http.get<any[]>(this.APIUrl + '/tasks/');
}
updateTask(val: any): Observable<any> {
  return this.http.put(this.APIUrl + `/tasks/${val.FormworkId}/`, val);
}



  addUser(user: any): Observable<any> {
    return this.http.post(`${this.APIUrl}/user/`, user);
  }

  updateUser(val: any) {
    return this.http.put(this.APIUrl + '/user/', val);
  }

  deleteUser(val: any) {
    return this.http.delete(this.APIUrl + '/user/' + val);
  }

  // Inside SharedService.ts
private currentUser: string = '';

setCurrentUser(username: string): void {
  this.currentUser = username;
}


  // Updated methods for user data management

  
  getUserData() {
    return this.userData.asObservable();
  }


  
  loginUser(mail: string, password: string): Observable<boolean> {
    return this.http.post<{ UserId: number, User: string, Nume: string, Pozitie: string, Membri: string, Prioritate: string }>(
      `${this.APIUrl}/login/`,  // Updated to use APIUrl
      { mail, password }
    ).pipe(
      map(response => {
        if (response) {
          this.setUserData(response);
          return true;
        } else {
          return false;
        }
      })
    );
  }
  
  public setUserData(user: any): void {
    this.currentUserSubject.next(user);
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
  logoutUser() {
    this.currentUserSubject.next(null);
    localStorage.removeItem('currentUser');
  }


  isLoggedIn(): boolean {
    return this.getCurrentUserValue() != null;
  }

  // Changed to public to allow access from other components
  public getUsrList(): Observable<any[]> {
    return this.http.get<any[]>('/api/users/');
  }


  // Method to get current user data
  getCurrentUser(): Observable<any> {
    return this.currentUser$;
  }

 // Method to get the cofraje for a specific user
 getUserCofraje(propietar: string): Observable<any[]> {
  return this.http.get<any[]>(`${this.APIUrl}/tasks/?propietar=${propietar}`);
}

deleteCofraj(id: number): Observable<any> {
  return this.http.delete(`${this.APIUrl}/tasks/${id}/`);
}
getCofrajeByPropietar(propietar: string): Observable<any[]> {
  return this.http.get<any[]>(`${this.APIUrl}/tasks/?propietar=${propietar}`);
}

getFormworks(): Observable<any[]> {
  return this.http.get<any[]>(`${this.APIUrl}/tasks?ordering=-FormworkId`);
}


  //photo api
  UploadPhoto(formData: FormData): Observable<any> {
    return this.http.post(`${this.APIUrl}/SaveFiles/`, formData);  // Make sure the URL ends with a slash
  }
  


addCofraj(cofrajData: any): Observable<any> {
  return this.http.post(`${this.APIUrl}/addCofraj/`, cofrajData);
}

addCofraj2(val:any){
  return this.http.post(this.APIUrl + '/tasks/',val);
}

private selectedFormwork: any;  // Temporary storage for selected formwork
setSelectedFormwork(cofraj: any): void {
  this.selectedFormwork = cofraj;
}

getSelectedFormwork(): any {
  return this.selectedFormwork;
}


getFormworkBySlug(slug: string): Observable<any> {
  return this.http.get<any>(`${this.APIUrl}/formworks/${slug}`);
}


// shared.service.ts

private selectedCofraj = new BehaviorSubject<any>(null);  // Create a BehaviorSubject to store the selected cofraj
selectedCofraj$ = this.selectedCofraj.asObservable();  // Expose the BehaviorSubject as an observable

// Method to set the selected cofraj
setSelectedCofraj(cofraj: any): void {
  this.selectedCofraj.next(cofraj);
}

// Method to get the selected cofraj
getSelectedCofraj(): Observable<any> {
  return this.selectedCofraj$;
}
sendPasswordResetEmail(email: string) {
  const body = { email };  // Ensure the key matches the expected field in Django
  return this.http.post<any>(`${this.APIUrl}/send-reset-password-email`, body, {
    headers: { 'Content-Type': 'application/json' }  // Set the content type
  });
}
expressInterest(formworkId: number, email: string) {
  const url = `http://127.0.0.1:8000/api/express-interest/${formworkId}/`;
  const body = { email: email };
  return this.http.post<any>(url, body);
}


}
