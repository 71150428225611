import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editformwork',
  templateUrl: './editformwork.component.html',
  styleUrls: ['./editformwork.component.css']
})
export class EditformworkComponent implements OnInit {
  counties: string[] = [];
  Denumire: string = '';
  Descriere: string = '';
  TipCofraj: string = '';
  Localitate: string = '';
  Nume: string = '';
  Telefon: string = '';
  Imagini: string = '';
  Propietar: string = '';
  Status: string = '';
  FormworkId: number = 0; // Added to track the ID of the formwork

  constructor(private service: SharedService, private router: Router) { }

  ngOnInit(): void {
    this.initializeCounties();
    this.prefillFormFields();
  }

  initializeCounties(): void {
    this.counties = [
      'Alba', 'Arad', 'Argeş', 'Bacău', 'Bihor', 'Bistriţa-Năsăud', 'Botoşani', 'Braşov', 'Brăila', 'Buzău',
      'Caraş-Severin', 'Călăraşi', 'Cluj', 'Constanţa', 'Covasna', 'Dâmboviţa', 'Dolj', 'Galaţi', 'Giurgiu', 'Gorj',
      'Harghita', 'Hunedoara', 'Ialomiţa', 'Iaşi', 'Ilfov', 'Maramureş', 'Mehedinţi', 'Mureş', 'Neamţ', 'Olt',
      'Prahova', 'Satu Mare', 'Sălaj', 'Sibiu', 'Suceava', 'Teleorman', 'Timiş', 'Tulcea', 'Vaslui', 'Vâlcea', 'Vrancea'
    ];
  }

  prefillFormFields(): void {
    this.service.getSelectedCofraj().subscribe(cofraj => {
      if (cofraj) {
        this.FormworkId = cofraj.FormworkId; // Track the formwork ID
        this.Denumire = cofraj.Denumire;
        this.Descriere = cofraj.Descriere;
        this.TipCofraj = cofraj.Tip;
        this.Localitate = cofraj.Localitate;
        this.Nume = cofraj.Propietar;
        this.Telefon = cofraj.Status;
        this.Imagini = cofraj.Imagini;
      }
    });
  }

  updateCofraj(): void {
    const val = {
      FormworkId: this.FormworkId, // Include the FormworkId in the update data
      Denumire: this.Denumire.trim(),
      Descriere: this.Descriere.trim(),
      Tip: this.TipCofraj,
      Localitate: this.Localitate,
      Propietar: this.Nume,
      Status: this.Telefon,
      Imagini: this.Imagini ? this.Imagini : '' // Ensure Imagini is a string (empty if not set)
    };

    this.service.updateTask(val).subscribe(
      res => {
        
        this.router.navigate(['/home']); // Navigate back to the account page after update
      },
      err => {
        alert('A apărut o eroare la actualizarea anunțului.');
        console.log('HTTP Error', err);
      }
    );
  }

  uploadPhotos(event: any): void {
    const files = event.target.files;
    const formData: FormData = new FormData();
  
    if (files.length > 4) {
        alert('Poți adăuga doar până la 4 imagini.');
        return;
    }
  
    for (let i = 0; i < files.length; i++) {
        formData.append('uploadedFiles', files[i], files[i].name);
    }
  
    this.service.UploadPhoto(formData).subscribe((data: any) => {
        this.Imagini = data.file_names.join(','); // Store as a comma-separated string
        console.log(this.Imagini);
    }, error => {
        console.log('Error uploading files', error);
    });
  }
}
