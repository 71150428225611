import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user = {
    Nume: '',
    Prenume: '',
    Mail: '',
    Parola: '',
    Telefon: '',
    Rol: '', // Add this field to track the selected role
    AcceptTerms: false
  };


  showErrorMessage: boolean = false;
  showTermsErrorMessage: boolean = false;

  constructor(private router: Router, private service: SharedService, private http: HttpClient) { }
  
  username!: string;
  password!: string;

  showConectareDiv: boolean = true;

  ngOnInit(): void {
    if (this.service.isLoggedIn()) {
      this.router.navigate(['/home']);
    }
  }

  showConectare() {
    this.showConectareDiv = true;
  }

  showInscriere() {
    this.showConectareDiv = false;
  }

  loginUser() {
    this.service.loginUser(this.username, this.password).subscribe(success => {
      if (success) {
        localStorage.setItem('currentUser', JSON.stringify(this.service.getCurrentUserValue()));
        this.router.navigate(['/home']);
      } else {
        alert('Parola sau email greșit');
        console.log('Login failed');
      }
    });
  }

  registerUser() {
    // Check if all fields are filled
    if (!this.user.Nume || !this.user.Prenume || !this.user.Mail || !this.user.Parola || !this.user.Telefon || !this.user.Rol) {
      this.showErrorMessage = true;
      return;
    } else {
      this.showErrorMessage = false;
    }

    // Check if terms are accepted
    if (!this.user.AcceptTerms) {
      this.showTermsErrorMessage = true;
      return;
    } else {
      this.showTermsErrorMessage = false;
    }

    this.service.addUser(this.user).subscribe(
      response => {
        if (response.message === "Added Successfully!!") {
          // After registration, automatically log in the user
          this.service.loginUser(this.user.Mail, this.user.Parola).subscribe(success => {
            if (success) {
              localStorage.setItem('currentUser', JSON.stringify(this.service.getCurrentUserValue()));
              alert('Inregistrat cu succes');
              this.router.navigate(['/home']);
            } else {
              alert('Inregistrare reușită, dar autentificarea a eșuat');
            }
          });
        } else {
          console.log('Error details:', response.details);
          alert('Inregistrarea a esuat');
        }
      },
      error => {
        console.log('An error occurred:', error);
        alert('An unexpected error occurred');
      }
    );
  }



  seetermeni() {
    const pdfPath = '../../assets/pdfs/Termeni si conditii_DMX.pdf';
    window.open(pdfPath, '_blank');
  }

  forgotPasswordEmail: string = '';
  showForgotPasswordModal: boolean = false;

  closeForgotPasswordModal() {
    this.showForgotPasswordModal = false;
  }
  openForgotPasswordModal() {
    this.showForgotPasswordModal = true;
  }

  sendForgotPasswordEmail() {
    this.service.sendPasswordResetEmail(this.forgotPasswordEmail).subscribe(
      response => {
        alert('Emailul pentru recuperarea parolei a fost trimis!');
        this.closeForgotPasswordModal();
      },
      error => {
        console.log('An error occurred:', error);
        alert('A apărut o problemă la trimiterea emailului.');
      }
    );
}

}