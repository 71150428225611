import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { CofrajeComponent } from './cofraje/cofraje.component';
import { AccountComponent } from './account/account.component';
import { AuthGuard } from './auth-guard.service';  // Import the guard
import { SeecofrajeComponent } from './cofraje/seecofraje/seecofraje.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'cofraje', component: CofrajeComponent },
  { path: 'vezi-cofrag/:slug', component: SeecofrajeComponent }, // <-- Route with slug parameter
  { path: 'contulmeu', component: AccountComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: HomeComponent },
];




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
