<head><link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" /></head>

<body>
    <header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <img (click)="seehome()" id="desktoplogo" src="../../assets/media/Logo DMX_w.png" alt="DMX Construction logo">
                <div class="all-nav-elements">
                    <div class="contact-nav">
                        <div class="flexRow">
                            <img id="mobilelogo" (click)="seehome()" src="../../assets/media/Logo DMX_w.png" alt="DMX Construction logo">
                        </div>
                        <div class="header-phone"><span id="header-icon-phone" class="material-symbols-outlined"><a href="tel:+40721229999"> </a> </span></div>
                        <div class="header-mail"><span id="header-icon-mail" class="material-symbols-outlined"> <a href="mailto:sales@thelake.ro"> </a></span></div>
                    </div>
                    <div class="logo-navbar">
                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                                <a class="blue-border" (click)="seehome()">Acasa</a>
                                <a class="blue-border" (click)="seecofraje()">Vezi cofraje</a>
                                <a class="blue-border" (click)="scrollToContact()">Contact</a>
                                <a (click)="contulmeu()">Profil</a>
                                <a class="blue-border" (click)="handleLoginLogout()">{{ loginText }}</a>
                            </div>
                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>
                        <div class="navbar">
                            <a (click)="seehome()">Acasa</a>
                            <a (click)="seecofraje()">Vezi cofraje</a>
                            <a (click)="scrollToContact()">Contact</a>
                            <a (click)="contulmeu()">Profil</a>
                            <a (click)="handleLoginLogout()">{{ loginText }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <section class="page-content-wrapper">
        <div class="page-content">
            <div class="filtre">
                <h3 (click)="seecofraje()">&#8810;&nbsp;<a style="cursor:pointer">Inapoi</a></h3>
            </div>

            <div class="cofraj-info-wrapper">
                <div class="cofraj-info">
                    <!-- Slider container -->
                    <div class="image-slider">
                        <div *ngFor="let image of cofraj.Imagini.split(','); let i = index" class="mySlides" [style.display]="i === slideIndex ? 'block' : 'none'">
                            <img class="cofraj-img" [src]="'https://api.cofraje.dmxconstruction.ro/media/' + image.trim()" alt="Image of {{ cofraj.Denumire }}">
                        </div>
                        <!-- Navigation controls -->
                        <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
                        <a class="next" (click)="plusSlides(1)">&#10095;</a>
                    </div>
                    <div class="offers-txt">
                        <div class="txt-flex">
                            <h3>{{ cofraj.Denumire }}</h3>
                            <label for="">Descriere:</label>
                            <p>{{ cofraj.Descriere }}</p>
                            <p class="tip">{{ cofraj.Tip }}</p>
                            <p class="localitate">{{ cofraj.Localitate }}</p>
                        </div>
                        <div class="contact-btns">
                            <p><span class="material-symbols-outlined">
                                person
                                </span> {{ cofraj.Propietar }}</p>
                            <button class="telefon" *ngIf="!phoneNumberVisible" (click)="showPhoneNumber()">Vezi telefon</button>
                            <p *ngIf="phoneNumberVisible">
                                <span class="material-symbols-outlined">call</span> {{ cofraj.Status }}</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <footer id="contact">
        <div class="gov">
            <img src="../../assets/media/1724831532016blob.jpg" alt="DMX Construction Logo" class="ajutor-financiar-img">

            <p class="ajutor-financiar">©  cofraje.dmxconstruction.ro – Toate drepturile sunt rezervate DMX Construction – 
                Obtinut cu sprijin financiar de la bugetul de stat prin Ministerul Economiei</p>
        </div>


        <div class="footer-wrapper">
            <div class="footer-logo">
                <img (click)="seehome()" src="../../assets/media/Logo DMX_w.png" alt="DMX Construction Logo">
            </div>
            <div class="footer1">
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target="_blank">
                    <p>Strada Tractorului, nr. 12-28,<br>Sibiu, România</p>
                </a>
                <div>
                    <span>T.</span><a href="tel:+40371306055">+40 371 306 055</a> <br><br>
                    <span>E.</span><a href="mailto:office@dmxconstruction.ro">office@dmxconstruction.ro</a>
                </div>
            </div>
            <div class="footer2">
                <div class="footer2-socialMedia">
                    <img src="../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" (click)="facebook()">
                    <img src="../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" (click)="linkedin()">
                    <img src="../../assets/media/icons/website_logo_icon.svg" alt="Instagram link" (click)="instagram()">
                </div>
                <div class="footer2-end">
                    <a>Politica de confidențialitate | Cookies</a>
                    <p>Brand & Web Design : <a href="" target="_blank">Hello Sib SRL</a></p>
                </div>
            </div>
        </div>
    </footer>
</body>
