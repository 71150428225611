<section class="page-content-wrapper">
  <div class="page-content">
    <div class="page-titles">
      <h1 class="title">Editează&nbsp;Anunț</h1>
    </div>
    <p class="about">
      Modifică câmpurile de mai jos pentru a actualiza anunțul selectat.
    </p>

    <div class="form-input">
      <form (ngSubmit)="updateCofraj()">
        <label for="denumire" >Denumire</label><br>
        <input type="text" maxlength="70" [(ngModel)]="Denumire" name="denumire" required><br>

        <label for="descriere">Descriere</label><br>
        <input type="text" maxlength="1000" [(ngModel)]="Descriere" name="descriere" required><br>

        <label for="tipCofraj">Tip Cofraj</label><br>
        <select [(ngModel)]="TipCofraj" name="tipCofraj" required>
          <option value="">Selectează</option>
          <option value="Scandura">Scandura</option>
          <option value="Lemn Stratificat">Lemn Stratificat</option>
          <option value="Metalic">Metalic</option>
        </select>

        <label for="localitate">Localitate</label>
        <select [(ngModel)]="Localitate" name="localitate" required>
          <option value="">Selectează</option>
          <option *ngFor="let county of counties" [value]="county">{{ county }}</option>
        </select>

        <label for="img">Adaugă Imagini</label><br>
        <input type="file" id="img" (change)="uploadPhotos($event)" accept="image/*" multiple>

        <h6>Informații de Contact:</h6>
        <label for="nume">Nume</label><br>
        <input type="text" [(ngModel)]="Nume" name="nume" readonly> <br>

        <label for="tel">Numarul de telefon</label> <br>
        <input type="text" [(ngModel)]="Telefon" name="tel" readonly>

        <button type="submit" class="send">Actualizează</button>
      </form>
    </div>
  </div>
</section>
